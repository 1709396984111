import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import {
  PRODUCT_ORIGINAL_MATTRESS_ID,
  PRODUCT_TERRA_MATTRESS_ID,
  PRODUCT_MYSA_MATTRESS_ID,
} from 'gatsby-env-variables';
import { useStaticQuery, graphql } from 'gatsby';

import { Layout } from '../../../components/layouts';
import { SEO } from '../../../components/common';
import { Product, Variant } from '../../../types/product';
import { getProductById } from '../../../utils/api';
import {
  CompareMattressHeroSection,
  CompareMattressSelectionSection,
  CompareMattressCardSection,
  CompareMattressAccordionSection,
  MattressFaqSection,
  WoosaPromiseIconGridSection,
  LatexMattressInfoSection,
} from '../../../components/sections';

const SectionCardAccordion = styled.div`
  background: var(--darkgrey);
  overflow-x: auto;
`;

const WoosaPromise = styled.div`
  background: var(--lightgrey);
`;

const productIds = [
  PRODUCT_ORIGINAL_MATTRESS_ID,
  PRODUCT_TERRA_MATTRESS_ID,
  PRODUCT_MYSA_MATTRESS_ID,
];

export type Props = {
  onChangeProductVariantDropdown?: (selected: Variant) => void;
  onCompare?: () => void;
  isLoading?: boolean;
  product: Product;
};

const ComparisonMattressPage: React.FC<Props> = () => {
  const mattressCardRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [preSelectedVariantType, setPreSelectedVariantType] = useState(
    'Single'
  );
  const [selectedVariantType, setSelectedVariantType] = useState('Single');

  const setPreSelected = (selected: Variant) => {
    setPreSelectedVariantType(selected.type);
  };

  const setSelected = () => {
    mattressCardRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
    setSelectedVariantType(preSelectedVariantType);
  };

  const {
    allProducts: { edges },
  } = useStaticQuery(graphql`
    query {
      allProducts(filter: { displayInStore: { eq: true } }) {
        edges {
          node {
            id
            name
            collection
            media {
              path
              default
              type
            }
            rating {
              avg
              count
              total
            }
            variants {
              type
              prices {
                amount
                currency
              }
              status
              statusDate {
                _seconds
              }
            }
            displayInStore
          }
        }
      }
    }
  `);

  const products = {
    [PRODUCT_ORIGINAL_MATTRESS_ID]: edges.find(
      ({ node }: { node: { id: string } }) =>
        node.id === PRODUCT_ORIGINAL_MATTRESS_ID
    ).node,
    [PRODUCT_TERRA_MATTRESS_ID]: edges.find(
      ({ node }: { node: { id: string } }) =>
        node.id === PRODUCT_TERRA_MATTRESS_ID
    ).node,
    [PRODUCT_MYSA_MATTRESS_ID]: edges.find(
      ({ node }: { node: { id: string } }) =>
        node.id === PRODUCT_MYSA_MATTRESS_ID
    ).node,
  };

  useEffect(() => {
    console.log('test pipeline');
    setIsLoading(true);
    const promises = productIds.map(id => getProductById(id));
    Promise.all(promises)
      .then(data => {
        for (const [key] of Object.entries(products)) {
          const dataProduct = data.find(d => d.id === key);
          if (!dataProduct) continue;
          products[key].variants = dataProduct.variants;
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Layout>
      <SEO
        title="Buy Natural Latex & Memory Foam Mattress in Singapore | Woosa"
        description="Shop for Mattresses in Singapore that are made from premium Natural Latex and Memory Foam."
      />
      <CompareMattressHeroSection />
      <CompareMattressSelectionSection
        onChangeProductVariantDropdown={selected => setPreSelected(selected)}
        onCompare={() => setSelected()}
        isLoading={isLoading}
        product={products[PRODUCT_ORIGINAL_MATTRESS_ID]}
      />

      <SectionCardAccordion ref={mattressCardRef}>
        <CompareMattressCardSection
          selectedVariantType={selectedVariantType}
          products={products}
        />
        <CompareMattressAccordionSection
          selectedVariantType={selectedVariantType}
          products={products}
        />
      </SectionCardAccordion>

      <Container>
        <Row lgJustifyContent="center">
          <Col lg={9}>
            <MattressFaqSection />
            <LatexMattressInfoSection />
          </Col>
        </Row>
      </Container>

      <WoosaPromise>
        <WoosaPromiseIconGridSection version={'v2'} />
      </WoosaPromise>
    </Layout>
  );
};
export default ComparisonMattressPage;
